<template>
    <div>
        <ts-page-title
            :title="$t('leaveRecord.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('leaveRecord.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button
                    type="info"
                    ghost
                    @click="$router.push({ name: 'leave-record-create' })"
                    >{{ $t("addNew") }}
                </Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('leaveRecord.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <div class="overflow-auto">
                <ts-table
                    :loading="this.loading"
                    class="tw-whitespace-nowrap "
                    :records="resources"
                    :columns="columns"
                    :rowGroup="{
                        field: 'leave_type.leave_type_en'
                    }"
                >
                    <template v-slot="{ record }">
                        <td width="100px">
                            <img
                                class="tw-rounded tw-h-8"
                                v-lazy="{
                                    src: photo(record),
                                    error:
                                        record.sex == 'Male'
                                            ? require('@/assets/staff-default-man.png')
                                            : require('@/assets/staff-default-women.png'),
                                    loading: require('@/assets/Rolling-1s-200px.svg'),
                                    preLoad: 1.3,
                                    attempt: 1
                                }"
                            />
                        </td>
                        <td width="150px" class="tw-align-middle">
                            {{ record.employee.employee_name_en }} |
                            {{ record.employee.employee_name_kh }}
                        </td>
                        <td
                            width="150px"
                            class="tw-align-middle tw-text-center"
                        >
                            {{ record.date_from }}
                        </td>
                        <td
                            width="150px"
                            class="tw-text-center tw-align-middle"
                        >
                            <span>
                                {{ record.time_from }}
                            </span>
                        </td>
                        <td
                            width="250px"
                            class="tw-text-center tw-align-middle"
                        >
                            {{ record.date_to }}
                        </td>
                        <td
                            width="250px"
                            class="tw-text-center tw-align-middle"
                        >
                            {{ record.time_to }}
                        </td>
                        <td
                            width="150px"
                            class="tw-text-center tw-align-middle"
                        >
                            {{ record.total_day }}
                        </td>
                        <td
                            width="150px"
                            class="tw-text-center tw-align-middle"
                        >
                            {{ record.leave_reason }}
                        </td>
                        <td
                            width="150px"
                            class="tw-align-middle tw-text-center"
                        >
                            {{
                                record.leave_type_sub_item
                                    ? record.leave_type_sub_item.leave_sub_item
                                    : null
                            }}
                        </td>
                        <td
                            class="tw-align-middle tw-text-center"
                            width="150px"
                        >
                            <a
                                href="#"
                                class="text-primary ml-2"
                                @click.prevent="onEdit(record)"
                                v-tooltip="$t('edit')"
                            >
                                <Icon type="ios-create" size="20" />
                            </a>
                            <Poptip
                                :title="$t('are_you_sure_to_delete')"
                                :transfer="true"
                                width="240"
                                v-if="!record._deleting"
                                @on-popper-show="
                                    () => (model.deleted_reason = '')
                                "
                            >
                                <a
                                    class="ml-2 text-danger"
                                    v-tooltip="$t('delete')"
                                    :disabled="record._deleting"
                                >
                                    <i
                                        class="fas fa-circle-notch fa-spin"
                                        v-if="record._deleting"
                                    ></i>
                                    <Icon type="ios-trash" size="20" v-else />
                                </a>
                                <div slot="content">
                                    <label class="required">{{
                                        $t("leaveRecord.reason")
                                    }}</label>
                                    <input
                                        v-model.trim="model.deleted_reason"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid':
                                                errors.has('deleted_reason') ||
                                                $v.model.deleted_reason.$error
                                        }"
                                    />
                                    <span
                                        class="invalid-feedback"
                                        v-if="reasonErrors.length > 0"
                                        >{{ reasonErrors[0] }}</span
                                    >
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('deleted_reason')"
                                    >
                                        {{ errors.first("deleted_reason") }}
                                    </div>
                                    <div class="tw-mt-2 tw-text-right">
                                        <a
                                            class="ivu-btn ivu-btn-primary ivu-btn-small"
                                            @click.prevent="onDelete(record)"
                                        >
                                            <span>OK</span>
                                        </a>
                                    </div>
                                </div>
                            </Poptip>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("branchName")
                }}</label>
                <ts-branch-filter @filter="value => (branch_id = value)" />
            </div>
        </Drawer>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";
import { required } from "vuelidate/lib/validators";
import { Errors } from "form-backend-validation";
export default {
    name: "leave-recordIndex",
    data() {
        return {
            loading: false,
            errors: new Errors(),
            width: "width: 300px",
            visible: false,
            branch_id: [],
            model: {
                deleted_reason: ""
            }
        };
    },
    validations: {
        model: {
            deleted_reason: { required }
        },
        form: ["model.deleted_reason"]
    },
    computed: {
        ...mapState("humanResource/leaveRecord", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.humanResource.leaveRecord.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/leaveRecord/SET_SEARCH",
                    newValue
                );
                this.$store.commit(
                    "humanResource/leaveRecord/RESET_CURRENT_PAGE"
                );
            }
        },
        reasonErrors() {
            const errors = [];
            if (!this.$v.model.deleted_reason.$error) return errors;
            !this.$v.model.deleted_reason.required && errors.push("Required");
            return errors;
        },
        columns() {
            return [
                {
                    name: this.$t("leaveRecord.photo")
                },
                {
                    name: this.$t("leaveRecord.employeeName")
                },
                {
                    name: this.$t("leaveRecord.dateFrom"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRecord.timeFrom"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRecord.dateTo"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRecord.timeTo"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRecord.total"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRecord.reason"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("leaveRecord.leaveSubItem"),
                    style: "text-align:center"
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center"
                }
            ];
        }
    },
    methods: {
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.sex == "female"
                ? require("@/assets/staff-default-women.png")
                : require("@/assets/staff-default-man.png");
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("humanResource/leaveRecord/fetch", attributes)
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onEdit(record) {
            this.$store.commit(
                "humanResource/leaveRecord/SET_EDIT_DATA",
                record
            );
            this.$router.push({
                name: "leave-record-edit",
                params: { id: record.lr_id }
            });
        },

        async onDelete(record) {
            this.$v.form.$touch();
            if (this.$v.form.$invalid) return;

            record._deleting = true;
            console.log(this.model);
            this.$store
                .dispatch("humanResource/leaveRecord/destroy", {
                    id: record.lr_id,
                    data: this.model
                })
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "leave Record",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500),
        branch_id: debounce(function(value) {
            this.fetchData({ branch_id: value });
        }, 500)
    },
    // beforeRouteEnter(to, from, next) {
    //     next(vm => {
    //         vm.fetchData();
    //     });
    // },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("humanResource/leaveRecord/RESET_STATE");
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
</style>
